import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@tkww/the-bash-frontend';
import { SisterBrands, TrustFact, TrustPilotWidget } from 'components/Trust';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import isBotFromUserAgent from 'helpers/isBot';
import { SectionContainer } from 'components/grid';
import sxPropType from 'propTypes/sx';

const StyledHeading = styled(Typography)({
  textAlign: 'center',
  margin: '0px 0px 32px 0px',
});

const StyledStack = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '21px',
  textAlign: 'center',
});

const TRUST_PILOT_STARS_TEMPLATE_ID = '53aa8807dec7e10d38f59f32';

const TrustSection = ({
  title,
  subtitle,
  userAgent,
  trustFacts,
  leftContent,
  rightContent,
  sx,
}) => {
  const hasTrustFacts = trustFacts && trustFacts.length > 0;
  return (
    <SectionContainer
      component="section"
      backgroundColor="transparent"
      leftContent={leftContent}
      rightContent={rightContent}
      sx={{
        position: 'relative',
        ...sx,
      }}
    >
      <StyledHeading
        variant="h2"
        sx={{
          marginBottom: { xs: 1, md: 3 },
        }}
      >
        {title}
      </StyledHeading>
      {subtitle && (
        <StyledHeading
          variant="body1"
          sx={{
            marginTop: { xs: 0 },
            marginBottom: hasTrustFacts ? { xs: 4, md: '67px' } : 4,
          }}
        >
          {subtitle}
        </StyledHeading>
      )}
      {hasTrustFacts && (
        <StyledStack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 4 }}>
          {trustFacts.map((fact) => (
            <TrustFact header={fact.title} subheader={fact.body} key={fact.body} />
          ))}
        </StyledStack>
      )}
      <SisterBrands />
      {!isBotFromUserAgent(userAgent) && (
        <TrustPilotWidget templateId={TRUST_PILOT_STARS_TEMPLATE_ID} />
      )}
    </SectionContainer>
  );
};

TrustSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  userAgent: PropTypes.string.isRequired,
  trustFacts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    })
  ),
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  sx: sxPropType,
};

TrustSection.defaultProps = {
  subtitle: null,
  leftContent: null,
  rightContent: null,
  sx: {},
  trustFacts: null,
};

export default TrustSection;
