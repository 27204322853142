import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '360px',
  height: '130px',
  backgroundColor: theme.palette.grey[200],
}));

const StyledTypography = styled(Typography)({
  margin: 0,
});

const TrustFact = ({ header, subheader }) => (
  <StyledCard variant="outlined" role="region">
    <CardContent
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '37px 29px 29px 29px !important',
      }}
    >
      <StyledTypography variant="h3">{header}</StyledTypography>
      <StyledTypography variant="body1">{subheader}</StyledTypography>
    </CardContent>
  </StyledCard>
);

TrustFact.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
};

export default TrustFact;
