import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { componentViewed } from 'state/modules/analytics';
/*
Example code for trustbox widget
https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application#add-a-trustbox-widget-with-a-react-application-1
*/

const TrustPilotWidget = ({ templateId }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [showWidget, setShowWidgets] = useState(false);

  const onViewChange = (inView) => {
    if (inView) {
      dispatch(componentViewed({ component: 'trustpilot widget ' }));
    }
  };

  // Create a reference to the <div> element which will represent the TrustBox
  // and to handle the intersection-observer inView event
  // props: https://www.npmjs.com/package/react-intersection-observer#api
  const { ref: inViewRef } = useInView({
    threshold: 0.5,
    triggerOnce: true,
    onChange: onViewChange,
  });

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      setShowWidgets(true);
    }
  }, []);

  React.useEffect(() => {
    if (showWidget) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [showWidget]);

  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef]
  );

  if (!showWidget) {
    return null;
  }

  return (
    <Box
      ref={setRefs}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id={templateId}
      data-businessunit-id="5196851b00006400053237a1"
      data-style-height="115px"
      data-style-width="100%"
      data-theme="light"
      data-tags="Homepage"
      data-stars="1,2,3,4,5"
      data-review-languages="en"
    >
      <a
        href="https://www.trustpilot.com/review/www.thebash.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </Box>
  );
};

TrustPilotWidget.propTypes = {
  templateId: PropTypes.string.isRequired,
};

export default TrustPilotWidget;
