import React from 'react';
import { getIconStateStyles } from '@tkww/the-bash-frontend';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import theKnotSrc from 'public/images-next/logos/theKnot.svg';

const StyledSpan = styled(Box)({
  paddingLeft: '5px',
  paddingRight: '5px',
});

const StyledDiv = styled(Box)({
  // size of the svg
  minWidth: 84,
  minHeight: 22,
  ...getIconStateStyles(theKnotSrc, null, null, false),
});

const TheKnotIcon = (props) => (
  <StyledSpan component="span">
    <StyledDiv {...props} />
  </StyledSpan>
);

export default TheKnotIcon;
