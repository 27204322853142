import React from 'react';
import { Typography } from '@tkww/the-bash-frontend';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TheKnotIcon from 'components/Icons/TheKnot';
import TheBumpIcon from 'components/Icons/TheBump';

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  textAlign: 'center',
  marginBottom: '60px',
});

const SisterBrands = () => (
  <StyledBox>
    <Typography variant="body1">
      The Bash is brought to you by the same family of brands as
    </Typography>
    <TheKnotIcon data-testid="theKnotIcon" role="img" aria-label="The Knot" />
    <Typography variant="body1"> & </Typography>
    <TheBumpIcon data-testid="theBumpIcon" role="img" aria-label="The Bump" />
  </StyledBox>
);

export default SisterBrands;
